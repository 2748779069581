<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-menu-button color="primary"></ion-menu-button>
        </ion-buttons>
        <ion-title>Plans</ion-title>
      </ion-toolbar>
    </ion-header>
    
    <ion-content :fullscreen="true">
      <div id="container">
        <div class="info">
            Select a plan to start your practice session. Your results will be tracked so you can compare to future sessions.
        </div>
        <ion-list>
          <ion-item v-for="plan in global.plans.state.plans" :key="plan.id" :router-link="{ name: 'PlanDetails', params: { id: plan.id } }">
              <ion-icon item-left :ios="golfSharp" :md="golfSharp"></ion-icon>
            <ion-label>
              <h2>{{ plan.title }}</h2>
              <p>{{ plan.description }}</p>
            </ion-label>
          </ion-item>
        </ion-list>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonList, 
  IonItem, 
  IonButtons, 
  IonContent, 
  IonHeader, 
  IonMenuButton, 
  IonPage, 
  IonTitle, 
  IonToolbar,
  IonLabel,
  IonIcon,
} from '@ionic/vue';
import { golfSharp } from 'ionicons/icons';

export default {
  name: 'PlansHome',
  inject: ['global'],
  components: {
    IonButtons,
    IonContent,
    IonHeader,
    IonMenuButton,
    IonPage,
    IonTitle,
    IonToolbar,
    IonList,
    IonItem,
    IonLabel,
    IonIcon,
  },

  setup() {

    return {
      golfSharp
    };
  },

  created() {
  },

  data() {
    return {
      colours: [
        'primary',
        'secondary',
        'tertiary',
        'success',
        'warning',
        'danger',
      ].sort(),

      colourAssign: {},
    };
  },

  methods: {
    getColour(val) {
      const colour = this.colourAssign[val] || this.colours[Object.keys(this.colourAssign).length] || this.colours[0];

      this.colourAssign[val] = colour;

      return colour;
    },
  },
}
</script>

<style scoped>
  ion-icon {
    margin-right: 1rem;
  }

  .info {
    margin: 2rem 2rem 1rem;
    font-weight: bold;
    text-align: center;
  }
</style>